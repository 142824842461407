import { COMMENT_TYPE_TINY } from '@/configs/constant';

import { api } from '../axios';
import type { IFetchFeedCommentsProps, IPostCommentsProps, IPostSubCommentsProps } from '../feed';

export const serialize = (obj: any) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export const createWorkflow = async (data: FormData) => {
  return api.post('/workflow-ai/create', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateWorkflow = async (data: FormData) => {
  return api.patch('/workflow-ai/update', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

type IPublishPrams = {
  name: string;
  description: string;
  private: boolean;
  customFields: Object;
  type: string;
  images: string[];
};

type IRunWorkFlow = {
  modelId: number;
  filed: {
    nodeId: string;
    nodeName: string;
    fieldName: string;
    fieldValue: any;
  };
};
export const publishWorkFlow = async ({
  id,
  data,
}: {
  id: string | Number;
  data: IPublishPrams;
}) => {
  return api.post(`/workflow-ai/${id}/publish`, data);
};

export const runWorkFlow = async (data: IRunWorkFlow) => {
  return api.post('/workflow-ai/run-ai-tool', data);
};

export const uploadFileWorkFlow = async (data: FormData) => {
  return api.post('/comfy/upload-file', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getWorkflowById = async (id: number | string) => {
  const res = await api.get(`/workflow-ai/${id}/details`);
  return res.data;
};

export const getListWorkFollow = async () => {
  const res = await api.get('/workflow-ai?size=50&offset=0');
  return res.data;
};

export const getListShowCase = async (params: {
  size: number;
  offset: number;
  sort?: string;
  filter?: string;
}) => {
  try {
    const res = await api.get('/workflow-ai/public', { params });
    return res.data;
  } catch (error) {
    return error;
  }
};

type IHistoryWorkflowPrams = {
  id: string | number;
  size: number;
  offset: number;
};
export const getRunHistoryWorkflow = async (params: IHistoryWorkflowPrams) => {
  return api.get(`/comfy/${params.id}/history`, {
    params: {
      size: params.size,
      offset: params.offset,
    },
  });
};

export const getRunHistoryAiTool = async (params: IHistoryWorkflowPrams) => {
  return api.get(`/workflow-ai/${params.id}/history`, {
    params: {
      size: params.size,
      offset: params.offset,
    },
  });
};

export const getHistoryById = async (id: string | number) => {
  return api.get(`/workflow-ai/${id}/history-details`);
};

export const getHistoryByIdFreeUser = async (modelId: string | number, ids: number[]) => {
  return api.get(`/workflow-ai/${modelId}/history-by-id?size=50&offset=0&filter={"ids":[${ids}]}`);
};

export const deleteHistories = async (ids: number[]) => {
  return api.patch('/comfy/history', {
    id: ids,
  });
};

export const uploadPublishFile = async ({ id, data }: { id: number; data: FormData }) => {
  return api.post(`/comfy/${id}/upload`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const likeWorkflow = async (modelId: number) => {
  const res = await api.post(`/reactions/${modelId}/like-ai-tools`);
  return res.data;
};

export const unLikeWorkflow = async (modelId: number) => {
  const res = await api.post(`/reactions/${modelId}/unlike-ai-tools`);
  return res.data;
};

export const postCommentWorkflow = async ({ postId, text }: IPostCommentsProps) => {
  const data = JSON.stringify({
    id: postId,
    text,
    type: COMMENT_TYPE_TINY.WORKFLOW,
  });
  try {
    const response = await api.post(`/comments/model`, data);
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postReplyCommentWorkflow = async ({ text, commentId }: IPostSubCommentsProps) => {
  try {
    const response = await api.post(`comments/model/sub-comment`, {
      commentId,
      text,
      type: COMMENT_TYPE_TINY.WORKFLOW,
    });
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const fetchFeedCommentsWorkflow = async ({
  id,
  size = 10,
  offset = 0,
}: IFetchFeedCommentsProps) => {
  try {
    const combineUrl: any = {};

    if (size) {
      combineUrl.size = size;
    }

    if (!isNaN(offset)) {
      combineUrl.offset = offset;
    }

    const response = await api.get(`/comments/model/${id}?${serialize(combineUrl)}`);

    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};
