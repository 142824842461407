import Link from 'next/link';
import React from 'react';
import { toast } from 'react-toastify';

import { useLayout } from '@/provider/LayoutProvider';

import { Images } from '..';
import { IconHeartNotLiked, IconRun } from '../assets';
import AvatarPlaceholder from '../avatar-default';
import { useRouter } from '@/provider/RouterProvider';
import { formatCash } from '@/utils';
import { AvatarDecor } from '@/app/shop/content/useFetch';
import LazyLoad from 'react-lazyload';

const CardWorkflow: React.FC<{
  image: string;
  title: string;
  provider: {
    picture: string;
    publicAddress: string;
    username: string;
    avatarDecoration: AvatarDecor;
  };
  id: string | number;
  totalUse?: number;
  totalLike?: number;
  status?: number;
  nodeCount?: number;
}> = ({ image, title, id, provider, totalUse = 0, totalLike = 0, nodeCount = 0, status }) => {
  const { setIsLoadingBar } = useLayout();
  const router = useRouter();

  return (
    <Link
      onClick={() => {
        status === 4 ? toast.error('Model maintenance!') : setIsLoadingBar(true);
      }}
      style={{ display: 'inherit' }}
      href={status === 4 ? {} : `/showcase/${id}`}
      className="group cursor-pointer rounded-[8px] border-1 border-[#E8E8E8] bg-white p-2 transition-all hover:border-orange-500"
    >
      <div className="relative overflow-hidden rounded-[8px] before:block before:pb-[calc((160/200)*100%)]">
        {image.includes('.mp4') ? (
          <LazyLoad className="absolute left-0 top-0 h-full w-full" offset={200} once>
            <video autoPlay loop muted className="h-full w-full object-cover">
              <source src={image} />
            </video>
          </LazyLoad>
        ) : (
          <Images
            className="absolute left-0 top-0 h-full w-full object-cover object-top"
            alt="workflow"
            src={image}
          />
        )}
        <div className="absolute bottom-2 sm:bottom-1 sm:left-1 left-2 z-10 flex items-center gap-2 rounded-full bg-[rgba(0,0,0,0.5)] px-2 sm:px-[6px] py-[3px] sm:py-[2px]">
          <div className="flex items-center">
            <IconHeartNotLiked width={20} height={20} color="white" />
            <span className="ml-[4px] text-[14px] text-white md:text-12">
              {formatCash(totalLike)}
            </span>
          </div>
          <span className="h-1 w-1 rounded-full bg-[#9DA3B5]" />
          <div className="flex items-center">
            <IconRun color="white" />
            <span className="ml-[4px] text-[14px] text-white md:text-12">
              {formatCash(totalUse)}
            </span>
          </div>
        </div>
        <p className="font-medium sm:hidden absolute left-2 top-2 z-10 text-white text-[12px] bg-[rgba(0,0,0,0.5)] rounded-full px-2 py-[3px]">
          {nodeCount} Nodes
        </p>
      </div>
      <div className="mt-3 px-[2px] pb-[2px]">
        <p className="line-clamp-1 h-[22px] text-[15px] font-semibold leading-[22px] sm:h-[18px] sm:text-[13px] sm:leading-[18px]">
          {title}
        </p>
        <div className="mt-2 flex items-center justify-between">
          <Link
            onClick={(e) => {
              e.preventDefault();
              router.push(`/profile/${provider.publicAddress}`);
            }}
            href={`/profile/${provider.publicAddress}`}
            className="flex items-center"
          >
            <div className="w-[18px] h-[18px] rounded-full relative">
              <AvatarPlaceholder
                src={provider.picture}
                address={provider.publicAddress}
                className="w-full h-full rounded-full"
                decor={provider.avatarDecoration}
                decorScale={8}
              />
            </div>
            <p className="ml-1 font-medium text-[13px] sm:text-[12px] pr-1 line-clamp-1 sm:max-w-[80px] xl:max-w-[130px]">
              {provider.username}
            </p>
          </Link>
          <div className="inline-block rounded-[8px] border-1 border-gray-800 px-3 py-[3px] text-[12px] transition-all group-hover:border-orange-500 group-hover:bg-orange-500 group-hover:text-white">
            Run
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardWorkflow;
